<template>
  <b-card>

    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h4>
        Groups
      </h4>
    </b-card-header>

    <b-card-body>

      <b-badge
        v-for="group in userData.groups"
        :key="group.id"
        class="mr-1"
      >
        <b-link
          :to="{ name: 'groups-detail', params: { id: group.id } }"
        >{{ group.name }}</b-link>
      </b-badge>

    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BLink,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style>

</style>
