<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            sm="12"
            class="d-flex align-items-center mb-2"
          >
            <h5 class="mb-0 font-weight-normal">
              Programs Registrations
            </h5>
          </b-col>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchProgramList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.logo"
          />
          <b-link
            :to="{ name: 'admin-application-edit', params: { id: data.item.application.id, type: 'Staff' } }"
          >{{ data.item.name }}</b-link>
        </template>

        <!-- Column: date_start -->
        <template #cell(date_start)="data">
          <a href="#">{{ data.value }}</a>
        </template>

        <!-- Column: date_end -->
        <template #cell(date_end)="data">
          <a href="#">{{ data.value }}</a>
        </template>

        <!-- Column: status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.application"
            :variant="`light-${resolveStatusVariant(data.item.application.status)}`"
          >
            {{ data.item.application.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <template>
              <b-dropdown-item
                v-if="data.item.application"
                :to="{ name: 'admin-application-edit', params: { id: data.item.application.id, type: 'Staff' } }"
              >
                <span>View Application</span>
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination,
  BDropdown, BDropdownItem, BAvatar, BBadge, BLink,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'

import vSelect from 'vue-select'
import store from '@/store'
import staffStoreModule from '@/views/admin/staff/staffStoreModule'
import useStaffProgramList from '@/views/admin/staff/staff-programs/useStaffProgramList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,

    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-staff'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, staffStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchProgramList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveStatusVariant,
    } = useStaffProgramList()

    return {
      fetchProgramList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,

      refetchData,

      resolveStatusVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.table-header {

  label {
    margin-bottom: 0;
  }
}

.badge {
  text-transform: uppercase;
}

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
